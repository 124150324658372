<template>
  <div class="main">
    <Head title="我的证书"></Head>
    <div class="el">
      <div class="el-item">
        <div class="btn-wrap" style="display:none">
          <el-button type="primary" size="small" @click="addCertificate"
          >添加证书
          </el-button
          >
        </div>
        <div class="el2">
          <el-table
              :data="tableData"
              border
              style="width: 100%"
              :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column type="index" width="50" label="序号"/>
            <!-- <el-table-column prop="certCode" label="证书编号" width="80"> </el-table-column> -->
            <!--            <el-table-column prop="certName" label="证书名称">-->
            <!--              <template slot-scope="scope">-->
            <!--                <el-button-->
            <!--                    type="text"-->
            <!--                    @click="handleDownLoad(scope.row)"-->
            <!--                >{{ scope.row.certName }}</el-button>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="courseName" label="课程名称"/>
            <!--          <el-table-column prop="certName" label="证照" width="150">-->
            <!--            <template slot-scope="scope">-->
            <!--              <img :src="scope.row.certUrl" class="img"/>-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column prop="publishTime" label="发证时间"></el-table-column>
            <el-table-column width="80" label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 1">未颁布</el-tag>
                <el-tag v-if="scope.row.status === 2">待提交</el-tag>
                <el-tag v-if="scope.row.status === 3">待审核</el-tag>
                <el-tag v-if="scope.row.status === 4">审核通过</el-tag>
                <el-tag v-if="scope.row.status === 5">审核失败</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作"  width="160">
              <template slot-scope="scope">
                <el-button
                    type="text"
                    size="mini"
                    @click="ShowCertificate(scope.row.certUrl)"
                >查看证书
                </el-button>
                    <el-button
                        v-if="scope.row.status !== 1"
                        type="text"
                        size="mini"
                        @click="handleRenewal(scope.row)"
                    >上传合格证明
                    </el-button>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <Pagination
              :total="search.total"
              :page.sync="search.page"
              :limit.sync="search.size"
              @pagination="getData"
          />
        </div>
      </div>
    </div>
    <!-- 证书申请延续弹窗 -->
    <CertificateSurviveDialog
        v-if="certificateSurviveDialog.show"
        :certificate-survive-dialog="certificateSurviveDialog"
        @getData="getData"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import CertificateSurviveDialog from "./components/CertificateSurviveDialog";
import {getUserCenterCert} from "@/api/common";
import Head from './Head'

export default {
  components: {
    Pagination,
    CertificateSurviveDialog,
    Head
  },
  data() {
    return {
      certificateSurviveDialog: {},
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      total: 0, //分页
      tableData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getUserCenterCert(this.search).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.search.total = res.data.data.total
          this.tableData = res.data.data.rows;
        }
      });
    },
    ShowCertificate(url) {
      window.open(url)
    },
    addCertificate() {
      this.$router.push({
        path: "addCertificate",
      });
    },
    // 上传合格证明
    handleRenewal(row) {
      this.certificateSurviveDialog.show = true;
      this.certificateSurviveDialog = {
        show: true,
        title: "上传合格证明",
        certId: row.certId,
        status: row.status,
        certUrl: row.certUrl,
      };
    },
    //下载
    handleDownLoad(row) {
      console.log(row.certUrl);
      let url = row.certUrl
      // let url = 'https://wlmqcol-1316857339.cos.ap-nanjing.myqcloud.com/courseResource/1680143662389.pdf'
      window.open(url, '_blank')
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  background: #fff;

  span {
    display: flex;
  }

  .el {
    margin-top: 20px;

    .el-item {
      margin-top: 20px;

      .btn-wrap {
        margin: 10px 0;
        text-align: right;
      }
    }
  }

  .pagination {
    margin-top: 30px;
  }

  .item {
    display: flex;
    justify-content: center;
  }

  .img {
    width: 80%;
    height: auto;
  }

  .el {
    padding: 10px 20px;
    margin-top: 0px !important;

    // min-height: 62vh;
  }

  .el2 {
  }
}
</style>