<template>
  <el-dialog
    v-if="certificateSurviveDialog.show"
    :title="certificateSurviveDialog.title"
    :visible.sync="certificateSurviveDialog.show"
    center
    width="600px"
    top="20vh"
  >
    <div class="cont">
      <el-form
        ref="ruleForm"
        v-loading="fromloding"
        :model="ruleForm"
        :rules="ruleFormRules"
        label-width="150px"
        class="ruleForm"
        :validate-on-rule-change="true"
      >
        <el-form-item label="证书类型：" prop="type_id">
          <el-select
            v-model="ruleForm.type_id"
            class="select"
            clearable
            placeholder="请选择证书类型"
            @change="typeChange"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.certificateName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="证书类别/专业：" prop="certificateId">
          <el-select
            v-model="ruleForm.certificateId"
            class="select"
            clearable
            placeholder="请选择证书类别/专业"
            @change="certificateChange"
          >
            <el-option
              v-for="item in certificateList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="证书编号：" prop="certCode">
          <el-input
            v-model="ruleForm.certCode"
            clearable
            type="text"
            class="input"
            placeholder="请输入证书编号"
            ></el-input
          >
        </el-form-item>
        <el-form-item label="继续教育完成日期：" prop="time">
          <el-date-picker
            v-model="ruleForm.time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期" el-upload
          >
          </el-date-picker> 
        </el-form-item>
        <el-form-item label="证书上传：" prop="certificateImgUrl">
          <div>
            <ImageUpload2
              :file-arr="ruleForm.img_url"
              multiple
              @getUploadList="getLogoList"
              
              :issubmit="ruleForm.status === 2 || ruleForm.status===5"
            />
            <p class="img-tip">
              支持JPG\JPEG\PNG格式，不超过5M！
            </p>
          </div>
        </el-form-item>
        <el-form-item label = "申请备注">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            placeholder="请输入内容"
            :autosize="{ minRows: 2, maxRows: 4 }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审核意见">
          <el-input
              type="textarea"
              v-if="ruleForm.auditRemark !== null"
              disabled
              v-model="ruleForm.auditRemark"
              placeholder="请输入内容"
              :autosize="{ minRows: 2, maxRows: 4 }"
          >
          </el-input>
        </el-form-item>
        <div class="btn-wrap">
          <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
          <el-button :disabled="isDisabled" type="primary" v-if="ruleForm.status === 2 || ruleForm.status===5" style="width: 120px" @click="btnSubmit"
            >提交申请</el-button
          >
        </div>
      </el-form>
      <!-- <div class="btn-wrap">
        <el-button @click="goBack" type="primary">关闭</el-button>
      </div> -->
    </div>
  </el-dialog>
</template>
<script>
//handlerSurvive
import { getCategory, getType,handlerSurvive, getRecord } from "@/api/myCenter";
import ImageUpload2 from "@/components/Upload/ImageUpload2";

export default {
  name: "OrderDetailDialog",
  components: {
    ImageUpload2,
  },
  props: {
    certificateSurviveDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      certificateImgUrl: "", // 证书图片路径

      typeList: [], //证书类型数据
      typeId: null,
      certificateList: [], // 证书类别/专业数据
      certificateId: null,
      loading: false,
      fromloding: false,
      isDisabled: false,
      ruleForm: {
        img_url: [],
        type_id: "", // 证书类型
        certificateId: "", // 证书类别/专业
        certCode: '',
        time: "",
        remark: "",
      },
      ruleFormRules: {
        type_id: [
          { required: true, message: "证书类型不能为空", trigger: "change" },
        ],
        certificateId: [
          {
            required: true,
            message: "证书类别/专业不能为空",
            trigger: "change",
          },
        ],
        certCode: [
          { required: true, message: "证书编号不能为空", trigger: "blur" },
        ],
        time: [
          {
            required: true,
            message: "继续教育完成日期不能为空",
            trigger: "change",
          },
        ],
      },
      pickerOptions: {
        onPick: (obj) => {
          this.pickerMinDate = new Date(obj.minDate).getTime();
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const day1 = 14 * 24 * 3600 * 1000;
            const maxTime = this.pickerMinDate + day1;
            const minTime = this.pickerMinDate - day1;
            return time.getTime() > maxTime || time.getTime() < minTime;
          }
        },
      },
    };
  },
  mounted() {
    this.getType();
    // this.getSurviveRecord();
    this.ruleForm.certId = this.certificateSurviveDialog.certId
    this.ruleForm.status = this.certificateSurviveDialog.status
    if(this.certificateSurviveDialog.status !== 2){
      this.getSurviveRecord();
    }else {
      this.ruleForm.img_url = this.certificateSurviveDialog.certUrl.split(',');
      this.certificateImgUrl = this.certificateSurviveDialog.certUrl.split(',');
    }
  },
  watch(){
  },
  methods: {
    getType() {
      getType().then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.typeList = res.data.data.rows;}
        }
      });
    },
    // 获取证书信息
    getSurviveRecord() {
      const obj = {
        certId: this.certificateSurviveDialog.certId,
      };
      getRecord(obj).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.ruleForm.type_id = res.data.data.certTypeId;
            this.getCategory(res.data.data.certTypeId);
            this.ruleForm.certificateId = res.data.data.categoryId;
            this.ruleForm.certCode = res.data.data.certCode;
            this.ruleForm.time = res.data.data.endEduTime;
            this.ruleForm.remark = res.data.data.remark;
            this.ruleForm.img_url = res.data.data.certificateUrl.split(',');
            this.ruleForm.auditRemark = res.data.data.auditRemark;
            this.certificateImgUrl = res.data.data.certificateUrl.split(',');
          }
        }
      });
      console.log("ruleform",this.ruleForm)
    },
    getCategory(id) {
      const obj = {
        certificateId: id,
      };
      getCategory(obj).then((res) => {
        this.certificateList = [];
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.certificateList = res.data.data.rows;
          }
        }
      });
    },
    //类型选择
    typeChange(val) {
      this.typeId = val;
      this.certificateList = [];
      this.ruleForm.certificateId = "";
      this.getCategory(this.typeId);
    },
    //专业选择
    certificateChange(val) {
      this.certificateId = val;
    },
    btnSubmit() {
      if (
        this.certificateImgUrl === null ||
        this.certificateImgUrl === undefined ||
        this.certificateImgUrl === ""
      ) {
        this.$message({
          type: "error",
          message: "请上传证书照片",
        });
        return;
      } else {
        this.$refs.ruleForm.validate((vaild) => {
          if (vaild) {
            console.log("pass...");
            console.log(this.ruleForm);
            const obj = {
              certificateUrl: this.certificateImgUrl.join(), //证书文件url 多个文件使用逗号隔开
              category_id: this.ruleForm.certificateId, // 证书类目id
              cert_type_id: this.ruleForm.type_id, // 证书类型id
              end_edu_time: this.ruleForm.time, // 结束教育时间
              cert_code: this.ruleForm.certCode, // 证书编号(页面填写的)
              cert_id: this.certificateSurviveDialog.certId, // 本站证书id(从表格此行的数据带过来)
              remark: this.ruleForm.remark, // 备注
            }
            this.isDisabled = true;
            handlerSurvive(obj).then(res=>{
              if(res.data.code === 0) {
                console.log(res);
                this.$message({
                  type: 'success',
                  message: '成功提交申请！',
                  onClose: ()=>{
                    this.certificateSurviveDialog.show = false
                    this.$emit('getData')
                  }
                })
              }else{
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
                this.isDisabled = false;
              }
            })




            /* this.$router.push({
              path: "certificate",
            }); */
          }
        });
      }
    },
    /* fileList(val) {
      console.log("hahaha:", val);
      this.certificateImgUrl = val;
    }, */
    goBack() {
      this.certificateSurviveDialog.show = false;
    },
    // 获取收集点门面照片
    getLogoList(val) {
      this.ruleForm.img_url = val
      this.certificateImgUrl = this.ruleForm.img_url
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  margin: 0px auto 0;
  .ruleForm {
    margin-top: 20px;
    .el-form-item__content {
      text-align: left !important;
    }
    .input {
      width: 220px;
    }
    .btn-wrap {
      margin-top: 20px;
    }
  }
  .el-form-item {
    // margin-left: 50px;
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: center;
  }
}
</style>